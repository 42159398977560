import { useFlagsmith } from 'flagsmith/react';
import { urls } from '../../libs/urls';
import { useUser } from './useUser';
import { useAuthStore } from '../../auth/infra/store/useAuthStore';

export const useHomeUrl = () => {
  const featureFlagsManager = useFlagsmith();
  const user = useUser();
  const { isTaxRegistered } = useAuthStore();
  if (user.status.isAuthenticated) {
    if (
      !isTaxRegistered &&
      featureFlagsManager.hasFeature('saas_register_redirect_tax_service')
    )
      return urls.taxCredentials;
    if (featureFlagsManager.hasFeature('saas_redirect_home')) return urls.home;
    if (featureFlagsManager.hasFeature('redirect_home_finance'))
      return urls.directFinance;

    return urls.payments;
  }
  return urls.login;
};
